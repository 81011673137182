.rev-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #5f5f5f;
  /* border: 2px solid blue; */
  padding: 2vh 0;
}

.rev-second-container {
  display: flex;
  width: 70vw;
  height: auto;
  /* border: 2px solid red; */
}

.col-1 {
  display: block;
  padding-right: 2vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
}
.col-2 {
  display: block;
  padding-right: 2vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
}
.col-3 {
  padding-top: 1vh;
  padding-bottom: 1vh;
}
.rev-title {
  width: auto;
  height: auto;
  padding: 1vh 1vw;
  display: flex;
  border: solid 1px #ededed;
  border-radius: 40px;
  float: left;
  margin: 10px 0;
}

.fa-star {
  color: orange;
  padding-top: 2px;
}
.rev-title-text {
  color: #253993;
  padding: 0 0.3vw;
  text-align: left;
  font-weight: bold;
}

.rev-msg {
  text-align: justify;
  float: left;
  padding: 0 0.2vw;
}

.rev-dot {
  float: left;
}
.fa-circle {
  padding: 1vh 0.5vw;
  color: #253993;
}

@media screen and (max-width: 960px) {
  .rev-second-container {
    display: block;
    width: 80vw;
    /* border: 2px solid red; */
  }

  /* .col-1 {
    float: left;
  } */
  .col-2 {
    float: left;
    /* padding-top: 1vh; */
  }
  .col-3 {
    float: left;
    /* padding-top: 1vh; */
  }

  .fa-circle {
    padding: 0.5vh 1vw;
    color: #253993;
  }
}

@media screen and (max-width: 700px) {
  .rev-second-container {
    width: 95%;
    /* border: 2px solid red; */
  }
}

@media screen and (max-width: 960px) and (orientation: landscape) {
  .rev-main-container {
    padding-top: 8vh;
  }

  .col-2 {
    padding-top: 5vh;
  }
  .col-3 {
    padding-top: 5vh;
  }

  .fa-circle {
    padding: 2vh 1vw;
    color: #253993;
  }
}
