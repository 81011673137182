.hoff-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 2vh 0;
}

.hoff-second-container {
  width: 70vw;
  height: auto;
}

.hoff-h2 {
  margin: 0;
  font-weight: normal;
  text-align: center;
  line-height: 0;
  background: none;
  padding: 1.3em 1em;
  /* border: solid 1px #ededed; */
  border-bottom: none;
  font: normal 22px "Lato";
  color: white;
}

.hoff-rectangle {
  color: white;
  height: 200px;
  background-color: #00346f;
  text-align: center;
  cursor: default;
  /* padding: 1.3em 1em; */
}

.hoff-value-box {
  display: inline-block;
  vertical-align: bottom;
  line-height: 1;
}

.hoff-h1 {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 60px;
  font-family: "Lato";
  line-height: 38px;
  margin: 0;
  padding: 0;
  border: 0;
}

.hoff-month {
  display: block;
  color: #253993;
  text-transform: uppercase;
  font-size: 11px;
  font-family: "Lato";
  line-height: 1em;
  background: #ffffff;
  height: auto;
  margin: 50px auto 0px auto;
  border-radius: 0;
  padding: 0.8em 0.5em;
  font-weight: bold;
  letter-spacing: 1px;
}

.hoff-box {
  color: #6e6e6e;
  border: 2px solid #00346f3d;
  border-width: 0 1px;
  padding: 15px 30px;
}

.hoff-button {
  /* background-color: ; */
  text-align: center;
  border: 1px solid #00346f3d;
  border-top: 0;
  padding-bottom: 20px;
}

.hoff-btn {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  /* font-size: 16px; */
  font-size: 16px;
  font-weight: 400;
  /* font-weight: bold; */
  /* overflow: hidden; */
  /* position: relative;
  z-index: 0;
  vertical-align: middle;
  user-select: none; */
  text-decoration: none;
  /* text-shadow: none; */
  /* border: 1px solid #253993; */
  /* padding: 0; */
  /* line-height: 1.15em; */

  /* box-shadow: none;
  font-style: normal; */
  padding: 0.75vmax 4vmax;
  background-color: #00346f;
  border-radius: 2vmax;
  color: white;
  transition: all 0.5s;
}

.hoff-btn:hover {
  transform: translateY(-10px);
}

@media screen and (max-width: 960px) {
  .hoff-second-container {
    width: 80vw;
    /* border: 2px solid red; */
  }

  .hoff-btn {
    font-size: 18px;
    font-weight: 500;
    padding: 1.6vmax 7vmax;
    transition: all 0.5s;
  }
}

@media screen and (max-width: 700px) {
  .hoff-second-container {
    width: 95%;
    /* border: 2px solid red; */
  }
}
