@import "react-calendar/dist/Calendar.css";

/* .calendar {
  margin-top: 10px;
} */

.footer-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #5f5f5f;
  /* border: 2px solid blue; */
  padding: 0 0;
  /* border: 2px solid red; */
  /* background-color: #040b90; */
}

.footer-second-container {
  display: block;
  width: 75vw;
  height: auto;
  /* border: 2px solid red; */
  /* border: 2px solid red; */
}

footer {
  /* margin-top: 10vmax; */
  margin-top: 5vh;
  padding: 2vmax;
  background-color: rgb(255, 255, 255);
  color: #040b90;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  border-top: 2px solid rgba(134, 134, 134, 0.452);
}

.footer-col1 {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  /* border: 2px solid red; */
}

.footer-col1 > h4 {
  font: 300 24px "Lato";
}

.footer-col1 > p {
  text-align: center;
  font-size: 1.2vmax;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

/* .footer-col1 > a {
  padding-bottom: 2vmax;
} */

.footer-logo > img {
  width: 30vmax;
  margin: 1vmax;
  cursor: pointer;
  box-sizing: border-box;
}

.footer-payments {
  display: flex;
  flex-direction: row;
  margin: 3vmax 0.5vmax;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.footer-payments > img {
  /* width: 20vmax; */
  margin: 0 5px;
}

.footer-col2 {
  width: 20%;
  text-align: center;
  align-items: center;
  box-sizing: border-box;

  /* margin-left: 3vw; */
  /* border: 2px solid purple; */
}

.footer-col2 > h4 {
  font: 300 24px "Lato";
  padding-top: 0.8vw;
}

.footer-col2 > p {
  max-width: 40%;
  font: normal 16px "Lato";
  /* margin: 1vmax auto; */
  margin: 0.4vmax auto;
  /* white-space: nowrap; */
}

.footer-col2 > p > a {
  text-decoration: none;
  color: #040b90;
}

.footer-col3 {
  width: 20%;

  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 2px solid magenta; */
}

.footer-col3 > h4 {
  font: 300 24px "Lato";
}

.footer-col3 > a {
  text-decoration: none;
  font: normal 14px "Lato";
  color: #040b90;
  margin: 0.5vmax;
}

.footer-col3 > a:hover {
  color: #eb4034;
}

.footer-col4 {
  width: 40%;
  margin: 1vmax 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 2px solid salmon; */
}

.footer-col4 > h4 {
  font: 300 24px "Lato";
}

.social-media {
  margin-top: 20px;
}
.social-media > h4 {
  font: 300 24px "Lato";
}

.social-media > a {
  text-decoration: none;
  cursor: pointer;
}

.social-media > a > i {
  padding: 10px 0 0 10px;
  /* padding-top: 10px; */
  font-size: 36px;
  /* border: 2px solid red; */
}

.fa-facebook {
  color: #3b5998;
}

.fa-twitter {
  color: #55acee;
}

.fa-instagram {
  color: #ac2bac;
}

.footer-col4 > a {
  text-decoration: none;
  font: normal 14px "Lato";
  color: #040b90;
  margin: 0.5vmax;
}

.footer-col4 > a:hover {
  color: #eb4034;
}

.plati-online > img {
  padding: 0.5vmax 0;
  /* width: 15vmax; */
}

@media screen and (max-width: 1270px) {
  .footer-second-container {
    /* display: block; */
    width: 90vw;
  }

  .footer-col1 {
    margin: 3vmax 0;
  }

  .footer-payments {
    margin: 5vmax 0;
  }
}
@media screen and (max-width: 960px) {
  footer {
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .footer-col1,
  .footer-col2,
  .footer-col3,
  .footer-col4 {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .footer-col2,
  .footer-col3,
  .footer-col4 {
    margin-top: 5vh;
  }

  .footer-logo > img {
    width: 40vmax;
  }
}

@media screen and (max-width: 700px) {
  .footer-second-container {
    width: 95%;
    /* border: 2px solid red; */
  }

  .footer-logo > img {
    width: 40vmax;
    margin: 0;
  }
}
