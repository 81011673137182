.why-container {
  display: block;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #5f5f5f;
  /* border: 2px solid red; */
}

.title-container {
  display: table;
  width: 70vw;
  margin-top: 10vh;
  margin-bottom: 5vh;
  margin-left: auto;
  margin-right: auto;
}

.title-container > span {
  display: table-cell;
  width: 50%;
  vertical-align: bottom;
}

.before-line-div {
  display: inline-block;
  width: 100%;
  border-top: 0 solid;
  /* padding-top: 3vh; */
  border-bottom: 2px solid;
  color: #ededed;
  vertical-align: baseline;
}
.title-container > h1 {
  padding: 0 0.5vw;
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  font-weight: bold;
  font: normal 36px "Lato";
}

.second-container {
  display: flex;
  justify-content: top;
  width: 70vw;
  height: 50vh;
  margin: 0 auto;
  /* border: 2px solid blue; */
}

.why-description {
  width: 35vw;
  text-align: justify;
  overflow: hidden;
  padding: 0 1vh;
}

.why-description > p {
  text-indent: 2vw;
  padding: 1vh 0;
}

.why-description > p > a {
  text-decoration: none;
}

.why-image-container {
  display: flex;
  margin: 1vh 1vh;
  width: 35vw;
}
.why-image {
  width: 100%;
  background-image: url("../../images/logo2.jpg");
  background-size: 100% 100%;
  object-fit: cover;
}
@media screen and (max-width: 960px) {
  .why-container {
    height: auto;
  }
  .title-container {
    width: 80vw;
  }

  .second-container {
    width: 80%;
    display: block;
    justify-content: center;
    text-align: center;
    height: auto;
  }
  .why-description {
    width: 95%;
  }
  .why-image-container {
    display: flex;
    margin: 1vh 1vh;
    width: 80vw;
  }

  .why-image {
    width: 98%;
    height: 70vh;
  }
}
@media screen and (max-width: 700px) {
  .why-container {
    height: auto;
  }
  .title-container {
    width: 95%;
  }
  .second-container {
    width: auto;
    height: auto;
    display: block;
    justify-content: center;
    text-align: center;
  }

  .why-description {
    width: 95%;
  }
  .why-image-container {
    width: 95vw;
  }
  .why-image {
    display: flex;
    padding: 0 0vh;

    /* display: flex; */
    width: 100%;
    height: 40vh;

    /* border: 2px solid red; */
    background-image: url("../../images/logo2.jpg");
    background-size: 100% 100%;
    object-fit: cover;
  }
}
