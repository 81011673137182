.contact-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #5f5f5f;
  /* border: 2px solid blue; */
  padding: 2vh 0;
}

.contact-second-container {
  display: flex;
  width: 80vw;
  /* height: 70vh; */
  box-sizing: border-box;
}

.contact-second-container > form {
  display: block;
  box-sizing: border-box;
}

.contact-col-1 {
  display: block;
  width: 50%;
  box-sizing: border-box;
  padding: 0 20px;
}

.contact-col-1 > h2 {
  padding: 20px 0;
}

.contact-col-1 > p {
  font: normal 14px "Lato";
  text-align: justify;
  /* padding: 3px 0; */
  /* text-indent: 10px; */
}

.contact-call-us {
  text-align: left;
  align-items: left;
  justify-content: left;
  padding: 10px 0;
  font: normal 16px "Lato";
}

.contact-call-us > a {
  text-decoration: none;
  cursor: pointer;
  color: #253993;
}

.contact-photo-container {
  display: block;
  padding: 1vh 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.contact-photo {
  display: flex;
  height: 40vh;
  background-image: url("../../images/contactimg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  object-fit: cover;
}

.contact-col-2 {
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  margin: 10px 10px;
}

/*****************************************************************************
*                                                                            *
*                          Media style                                       *
*                      Max-Resolution: 1450px                                *
*                                                                            *
******************************************************************************/
@media screen and (max-width: 1450px) {
  .contact-second-container {
    width: 92.5vw;
  }
}

/*****************************************************************************
*                                                                            *
*                          Media style                                       *
*                      Max-Resolution: 960px                                 *
*                                                                            *
******************************************************************************/
@media screen and (max-width: 960px) {
  .contact-second-container {
    display: block;
    height: auto;
    align-items: center;
    text-align: center;
  }

  .contact-col-1 {
    width: 100%;
    padding-bottom: 20px;
  }

  .contact-col-2 {
    width: 95%;
  }
}
