.reviews-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #5f5f5f;
  /* border: 2px solid blue; */
  padding: 2vh 0;
}

.reviews-second-container {
  display: block;
  width: 70vw;
  height: auto;
  box-sizing: border-box;
  /* border: 2px solid red; */
}

.reviews-row-1 {
  display: flex;
  height: auto;
}

.reviews-row-2 {
  display: flex;
  width: auto;
  /* border: 2px solid black; */
}

.reviews-col-1 {
  padding-right: 2vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.reviews-col-2 {
  display: block;
  padding-right: 2vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
}
.reviews-col-3 {
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-right: 2vw;
}
.reviews-rev-title {
  width: auto;
  height: auto;
  padding: 1vh 1vw;
  display: flex;
  border: solid 1px #ededed;
  border-radius: 40px;
  float: left;
  margin: 10px 0;
}

.fa-star {
  color: orange;
  padding-top: 2px;
}
.reviews-rev-title-text {
  color: #253993;
  padding: 0 0.3vw;
  text-align: left;
  font-weight: bold;
}

.reviews-rev-msg {
  text-align: justify;
  float: left;
  padding: 0 0.2vw;
  font: normal 14px "Lato";
}

.reviews-rev-dot {
  float: left;
}
.reviews-fa-circle {
  padding: 1vh 0.5vw;
  color: #253993;
}

/*****************************************************************************
*                                                                            *
*                          Media style                                       *
*                      Max-Resolution: 1450px                                *
*                                                                            *
******************************************************************************/
@media screen and (max-width: 1450px) {
  .reviews-second-container {
    width: 90%;
  }
}

/*****************************************************************************
*                                                                            *
*                          Media style                                       *
*                      Max-Resolution: 960px                                 *
*                                                                            *
******************************************************************************/
@media screen and (max-width: 960px) {
  .reviews-second-container {
    display: block;
    width: 80vw;
    /* border: 2px solid red; */
  }

  .reviews-row-1,
  .reviews-row-2 {
    display: block;
    /* width: 100%;
    margin: 0 0;
    padding: 0 0; */
  }

  .reviews-col-1,
  .reviews-col-2,
  .reviews-col-3,
  .reviews-col-4 {
    float: left;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    /* margin: 0;
    padding: 0 0; */
  }

  .reviews-col-4 {
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-right: 2vw;
  }

  .reviews-fa-circle {
    padding: 0.5vh 1vw;
    color: #253993;
  }
}

/*****************************************************************************
*                                                                            *
*                          Media style                                       *
*                      Max-Resolution: 700px                                 *
*                                                                            *
******************************************************************************/
@media screen and (max-width: 700px) {
  .reviews-second-container {
    width: 95%;
    /* border: 2px solid red; */
  }
}

/*****************************************************************************
*                                                                            *
*                          Media style                                       *
*                      Max-Resolution: 700px                                 *
*                     Orientation: landscape                                 *
*                                                                            *
******************************************************************************/
@media screen and (max-width: 960px) and (orientation: landscape) {
  .reviews-main-container {
    padding-top: 8vh;
  }

  .reviews-col-2 {
    padding-top: 5vh;
  }
  .reviews-col-3 {
    padding-top: 5vh;
  }

  .reviews-fa-circle {
    padding: 2vh 1vw;
    color: #253993;
  }
}
