.comp-container {
  display: block;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
}

.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.slider-container > img {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
}
