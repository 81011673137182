.faq-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #5f5f5f;
  /* border: 2px solid blue; */
  padding: 2vh 0;
}

.faq-second-container {
  /* display: flex; */
  width: 70vw;
  height: auto;
  margin-top: 20px;
  /* border: 2px solid red; */
}

.faq-row {
  width: 100%;
  align-items: left;
  text-align: left;
  justify-content: left;
  /* border: 2px solid blue; */
}

.faq-question {
  display: table;
}
.Q {
  font-size: 30px;
  height: 30px;
  line-height: 1em;
  padding: 7px 8px;
  border: 2px solid #253993;
  width: auto;
  min-width: 1em;
  color: #253993;
  margin: -0.04em 15px -0.07em 0;
}

.faq-q {
  display: table-cell;
  vertical-align: middle;
  font: normal 22px "Lato";
  color: #4a4f55;
}
.faq-answer {
  display: table;
}

.A {
  font-size: 30px;
  height: 30px;
  line-height: 1em;
  padding: 7px 8px;
  border: 2px solid #253993;
  width: auto;
  min-width: 1em;
  color: #253993;
  margin: 20px 15px 20px 0;
  text-align: center;
  /* margin: 10px 0; */

  /* float: left; */
  /* font-weight: 400;
  overflow: visible; */

  /* vertical-align: middle;
  font-family: "Lato"; */
}

.faq-a {
  display: table-cell;
  vertical-align: middle;
  font: normal 14px "Lato";
  color: #4a4f55;
}

.faq-line {
  position: relative;
  display: block;
  clear: both;
  margin: 40px 0 30px 0;
  min-width: 100%;
  height: 1px;
  border-top: 1px solid;
  color: #ededed;
}

/*****************************************************************************
*                                                                            *
*                          Media style                                       *
*                      Max-Resolution: 960px                                 *
*                                                                            *
******************************************************************************/
@media screen and (max-width: 960px) {
  .faq-line {
    margin: 20px 0 15px 0;
  }
}

/*****************************************************************************
*                                                                            *
*                          Media style                                       *
*                      Max-Resolution: 700px                                 *
*                                                                            *
******************************************************************************/
@media screen and (max-width: 700px) {
  .faq-second-container {
    width: 95%;
    /* border: 2px solid red; */
  }
}
