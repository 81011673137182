.call-banner {
  display: none;
}

.navbar {
  background-color: white;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 999;
  white-space: nowrap;
  /* background-color: red; */
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
  /* max-width: 100vw; */
}

.navbar-logo {
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.navbar-logo > img {
  max-height: 30vh;
  max-width: 20vw;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 0px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  /* margin-right: 2rem; */
}

.nav-item {
  height: 52px;
}

.nav-links {
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0rem 1rem;
  height: 100%;
}

.nav-links.active > span {
  border-bottom: 1px solid #253993;
  transition: all 0.2s ease-out;
}

.nav-links:hover > span {
  border-bottom: 1px solid #253993;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #000;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .navbar-logo {
    position: absolute;
    height: 10vh;
    width: 10vh;
    left: 2.5vh;
    /* transform: translate(25%, 50%); */
  }

  .navbar-logo > img {
    max-height: 100vh;
    max-width: 70vw;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 15vh;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: center;
  }

  .nav-menu.active {
    background: rgba(0, 0, 0, 0.8);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    /* border: solid green; */
    justify-content: center;
  }
  .nav-item {
    top: 0px;
    height: 10vh;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* padding-bottom: 5vh; */
  }
  .nav-links {
    align-items: center;
    color: white;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    padding: 0;
    top: 0px;
    max-width: 100vh;
    display: flex;
    /* border: solid green; */
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    color: #000;
    right: 0;
    font-size: 1.8rem;
    cursor: pointer;
    margin: 5px 32px;
  }

  .fa-times {
    color: #000;
    font-size: 2rem;
  }
}

@media screen and (max-width: 650px) {
  .call-banner {
    display: flex;
    background-color: #000cf2;
    color: white;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 8vh;
    font-weight: bold;
    text-shadow: 0 1px 0px rgb(0 0 0 / 18%);
    text-decoration: none;
  }

  .call-banner > i {
    font-size: 4vh;
    padding-right: 1vh;
  }

  .call-banner > a {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 100%;
    text-decoration: none;
    color: white;
  }

  .nav-menu {
    top: 23vh;
  }
}

@media screen and (max-width: 320px) {
}

@media screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .nav-links {
    max-width: 100vw;
  }
}
