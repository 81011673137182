.cpage-1container {
  position: relative;
  z-index: 2;
  margin-bottom: 32px;
}

.cpage-2container {
  background-color: black;
  color: white;
  background-repeat: no-repeat;
  background-position: center top;
  background-attachment: scroll;
  background-size: auto;

  /* min-height: 10vh;
  text-align: center;
  justify-content: center; */
}
.cpage-3container {
  display: block;
  float: none;
  margin: auto;
  /* min-width: 320px; */
  min-height: 100%;
  max-width: 1380px;
  width: auto;

  padding: 1.2vw 0;
  text-align: center;
}

.cpage-4container {
  text-align: center;
  padding: 1.2vw 0;
}

.cpage-4container > h1 {
  color: #b3b3b3;
  font: normal 36px "Lato";
}

.pPlan-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #5f5f5f;
  padding: 2vh 0;
}

.pPlan-second-container {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  text-align: center;
}

/*****************************************************************************
*                                                                            *
*                           First Offer                                      *
*                                                                            *
******************************************************************************/

.has-price {
  margin-right: -10px;
  margin-left: -10px;
  padding-top: 10px;
}

.grid-1-3 {
  width: 33.33333333%;
  position: relative;
  float: left;
  box-sizing: border-box;
  display: block;
  padding: 0 15px;
}

.price-outer-wrapper {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: transparent;
  background-image: linear-gradient(340deg, #f7faff 0%, #f1f3f6 100%);
  border-radius: 10px;
}

.price-wrapper .price-title {
  margin: 0;
  font-weight: normal;
  text-align: center;
  line-height: 0;
  background: none;
  padding: 1.3em 1em;
  border: solid 1px #ededed;
  border-bottom: none;
}

.price-wrapper .price-title > h3 {
  font: normal 22px "Lato";
  color: #4a4f55;
  display: block;
}

.price {
  text-align: justify;
}

.value-box {
  display: block;
  color: #081f28;
  height: 150px;
  /* background: #a7d9ee; */
  text-align: center;
  align-items: center;
  justify-content: center;
  /* line-height: 200px; */
  cursor: default;
  padding-left: 2vmax;
}

.value-box-content {
  height: 100%;
  display: flex;
  vertical-align: bottom;
  line-height: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.value-box-bottom-line {
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 1px;
  border-bottom: 1px solid #d4dbe4;
}

.value-box-content > div > h3 {
  font-size: 22px;
  margin-bottom: 1vmax;
  color: #002855;
}

.value-box-content > div > h2 {
  font-size: 48px;
  color: #00346f;
}

.value {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 60px;
  font-family: "Lato";
  line-height: 38px;
}

.meta {
  display: block;
  color: #253993;
  text-transform: uppercase;
  font-size: 11px;
  font-family: "Lato";
  line-height: 1em;
  background: #ffffff;
  height: auto;
  margin: 50px auto 0px auto;
  border-radius: 0;
  padding: 0.8em 0.5em;
  font-weight: bold;
  letter-spacing: 1px;
}

.content-box {
  color: #6e6e6e;

  border-width: 0 1px;
  padding: 15px 30px;
}

.content-box > ul {
  margin: 0;
  list-style: none;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.content-box > ul > li > svg {
  font-size: 22px;
  padding: 0 10px;
  color: #00346f;
}

.content-box > ul > li {
  text-align: left;
  justify-content: left;
  justify-items: center;
  font-size: 18px;
  color: #2b2f34;
  /* line-height: 1em; */
  padding: 12px 0;
  display: flex;
}

.content-box > ul > li > div {
  color: #898f95;
  font-size: 16px;
}

.meta-box {
  padding: 10px 10px 25px;
  text-align: center;
  border-top: 0;
}

.meta-box > a {
  /* margin: 15px auto 0;
  border: 1px solid #253993; */
}

.meta-box > a > span {
  font-style: normal;
  /* text-transform: none; */
  font-size: #253994;
  position: relative;
  vertical-align: top;
  padding: 7% 1em;
  font-weight: 200;
}

.pPlan-btn {
  /* font-size: 1em;
  font-weight: normal; */
  display: inline-block;
  text-align: center;
  cursor: pointer;
  /* font-size: 16px; */
  font-size: 16px;
  font-weight: 400;
  /* font-weight: bold; */
  /* overflow: hidden; */
  /* position: relative;
  z-index: 0;
  vertical-align: middle;
  user-select: none; */
  text-decoration: none;
  /* text-shadow: none; */
  /* border: 1px solid #253993; */
  /* padding: 0; */
  /* line-height: 1.15em; */

  /* box-shadow: none;
  font-style: normal; */
  padding: 0.75vmax 4vmax;
  background-color: #00346f;
  border-radius: 2vmax;
  color: white;
  transition: all 0.5s;
}

.pPlan-btn:hover {
  /* background: #253993;
  color: #a7d9ee; */
  transform: translateY(-10px);
}

/*****************************************************************************
*                                                                            *
*                           Second Offer                                     *
*                                                                            *
******************************************************************************/

.price-outer-wrapper2 {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: transparent;
  background-image: linear-gradient(340deg, #002855 0%, #00346f 100%);
  border-radius: 10px;
}
.value-box2 {
  display: block;
  color: #081f28;
  height: 150px;
  /* background: #a7d9ee; */
  text-align: center;
  align-items: center;
  justify-content: center;
  /* line-height: 200px; */
  cursor: default;
  padding-left: 2vmax;
}

.value-box2-content {
  height: 100%;
  display: flex;
  vertical-align: bottom;
  line-height: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.value-box2-content > div > h3 {
  font-size: 22px;
  margin-bottom: 1vmax;
  color: white;
}

.value-box2-content > div > h2 {
  font-size: 48px;
  color: white;
}

.value2 {
  margin-top: 0px;
  font-size: 60px;
  line-height: 48px;
  color: #ffffff;
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-family: "Lato";
}

.meta2 {
  display: block;
  color: #253993;
  text-transform: uppercase;
  font-size: 11px;
  font-family: "Lato";
  line-height: 1em;
  background: #ffffff;
  height: auto;
  margin: 50px auto 0px auto;
  border-radius: 0;
  padding: 0.8em 0.5em;
  font-weight: bold;
  letter-spacing: 1px;
}

.content-box2 {
  color: white;

  border-width: 0 1px;
  padding: 15px 30px;
}

.content-box2 > ul {
  margin: 0;
  list-style: none;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.content-box2 > ul > li > svg {
  font-size: 22px;
  padding: 0 10px;
  color: white;
}

.content-box2 > ul > li {
  text-align: left;
  justify-content: left;
  justify-items: center;
  font-size: 18px;
  color: white;
  /* line-height: 1em; */
  padding: 12px 0;
  display: flex;
}

.content-box2 > ul > li > div {
  color: white;
  font-size: 16px;
}

.meta-box2 {
  padding: 10px 10px 25px;
  text-align: center;
  border-top: 0;
}

.meta-box2 > a {
  /* margin: 15px auto 0;
  border: 1px solid #253993; */
}

.meta-box2 > a > span {
  font-style: normal;
  /* text-transform: none; */
  /* font-size: #253994; */
  position: relative;
  vertical-align: top;
  padding: 7% 1em;
  font-weight: 200;
}

.pPlan-btn2 {
  /* font-size: 1em;
  font-weight: normal; */
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  /* overflow: hidden; */
  /* position: relative;
  z-index: 0;
  vertical-align: middle;
  user-select: none; */
  text-decoration: none;
  /* text-shadow: none; */
  /* border: 1px solid #253993; */
  /* padding: 0; */
  /* line-height: 1.15em; */

  /* box-shadow: none;
  font-style: normal; */
  padding: 0.75vmax 4vmax;
  background-color: white;
  border-radius: 2vmax;
  color: #00346f;
  transition: all 0.5s;
}

.pPlan-btn2:hover {
  /* background: #253993;
  color: #a7d9ee; */
  transform: translateY(-10px);
}

/*****************************************************************************
*                                                                            *
*                          Media style                                       *
*                      Max-Resolution: 1450px                                *
*                                                                            *
******************************************************************************/
@media screen and (max-width: 1450px) {
  .pPlan-second-container {
    width: 92.5vw;
  }
}

/*****************************************************************************
*                                                                            *
*                          Media style                                       *
*                      Max-Resolution: 1100px                                *
*                                                                            *
******************************************************************************/
@media screen and (max-width: 1100px) {
  .pPlan-second-container {
    width: 90vw;
  }
}

/*****************************************************************************
*                                                                            *
*                          Media style                                       *
*                      Max-Resolution: 960px                                 *
*                                                                            *
******************************************************************************/
@media screen and (max-width: 960px) {
  .cpage-4container > h1 {
    font-size: 24px;
  }

  .pPlan-second-container {
    display: block;
    width: 80vw;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
  }
  .grid-1-3 {
    display: block;
    position: relative;
    float: none;
    clear: both;
    box-sizing: border-box;
    display: block;
    padding: 0 15px;
    padding-bottom: 30px;
    width: 100%;
  }

  .pPlan-btn {
    font-size: 18px;
    font-weight: 700;
    padding: 2vmax 10vmax;
    transition: all 0.5s;
  }

  .pPlan-btn2 {
    font-size: 18px;
    font-weight: 700;
    padding: 2vmax 10vmax;
    transition: all 0.5s;
  }
}

/*****************************************************************************
*                                                                            *
*                          Media style                                       *
*                      Max-Resolution: 700px                                 *
*                                                                            *
******************************************************************************/
@media screen and (max-width: 700px) {
  .pPlan-second-container {
    width: 95%;
    /* border: 2px solid red; */
  }
}
