.contactform-main-container {
  margin-top: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #5f5f5f;
  background-color: #eeeeee;
  padding: 2vh 0;
}

.contactform-second-container {
  display: block;
  width: 70vw;
  height: auto;
}

/* .contactform-Form {
} */

.w50 {
  align-items: left;
  text-align: left;
}

.w50 > label {
  font: normal 14px "Lato";
  color: #787878;
  cursor: pointer;
}

.w50 > input {
  background: #ffffff;
  border-radius: 0px;
  padding: 17px;
  width: 100%;
  position: relative;
  border: 1px solid #ededed;
  border-bottom: 1px solid transparent;
  margin: 0 0 20px 0;
  box-shadow: none;
  font-size: 14px;
  -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 10%);
  color: #2b2f34;
  box-sizing: border-box;
}

.w50 > input:focus {
  border: 1px solid #253993;
}

.w50 > textarea:focus {
  border: 1px solid #253993;
}

.w50 > textarea {
  position: relative;
  overflow: auto;
  min-height: 10rem;
  resize: none;
  background: #ffffff;
  border-radius: 0px;
  padding: 17px;
  width: 100%;

  border: none;
  outline: none;
  border: 1px solid #ededed;
  border-bottom: 1px solid transparent;
  margin: 0 0 20px 0;
  box-shadow: none;
  font-size: 14px;
  -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 10%);
  color: #2b2f34;
  box-sizing: border-box;
}

.checkbox-area {
  align-items: left;
  justify-content: left;
  text-align: left;
  padding-bottom: 3vh;
}

.checkbox-area > label {
  padding-left: 3px;
  padding-right: 12px;
}

.w50 > button {
  margin-top: 0;
  font-size: 1em;
  font-weight: normal;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  z-index: 0;
  vertical-align: middle;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  text-shadow: none;
  border: 0;
  padding: 0;
  line-height: 1.15em;
  background: #253993;
  color: #ffffff;
  padding: 0.8em 1.6em;
}

.w50 > button:hover {
  background: #a7d9ee;
  color: #253993;
}
@media screen and (max-width: 960px) {
  .contactform-second-container {
    display: block;
    width: 80vw;
  }
}

@media screen and (max-width: 700px) {
  .contactform-second-container {
    width: 95%;
  }
}

@media screen and (max-width: 960px) and (orientation: landscape) {
}
