.whyus-container {
  display: block;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #5f5f5f;
}

.whyus-title-container {
  display: table;
  width: 100%;

  margin-bottom: 8vh;
  margin-left: auto;
  margin-right: auto;
}

.whyus-title-container > span {
  display: table-cell;
  width: 50%;
  vertical-align: bottom;
}

.whyus-before-line-div {
  display: inline-block;
  width: 100%;
  border-top: 0 solid;
  /* padding-top: 3vh; */
  border-bottom: 2px solid;
  color: #ededed;
  vertical-align: baseline;
}

.whyus-second-container {
  display: flex;
  justify-content: top;
  width: 70vw;
  height: auto;
  margin: 0 auto 4vh auto;
}

.whyus-description {
  width: 40vw;
  text-align: justify;
  overflow: hidden;
  padding: 0 1vh;
}

.whyus-description > p {
  font: normal 14px "Lato";
  text-indent: 2vw;
  padding: 1vh 0;
}
.whyus-image-container {
  display: flex;
  margin: 1vh 1vh;
  width: 30vw;
  box-sizing: border-box;
}
.whyus-image {
  /* width: 100%;
  background-image: url("../../images/whyusimg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%; */
  /* object-fit: cover; */
  box-sizing: border-box;
}

.whyus-image > img {
  display: block;
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
}

@media screen and (max-width: 960px) {
  .whyus-container {
    height: auto;
  }

  .whyus-second-container {
    width: 80%;
    display: block;
    justify-content: center;
    text-align: center;
    height: auto;
  }
  .whyus-description {
    width: 95%;
  }
  .whyus-image-container {
    width: 98%;
  }
}
@media screen and (max-width: 700px) {
  .whyus-container {
    height: auto;
  }

  .whyus-second-container {
    width: auto;
    height: auto;
    display: block;
    justify-content: center;
    text-align: center;
  }

  .whyus-description {
    width: 95%;
  }
  .whyus-image-container {
    width: 95%;
    /* width: 95vw; */
  }
  .whyus-image {
    /* display: flex;
    padding: 0 0vh; */

    /* display: flex; */
    /* width: 100%;
    height: 40vh; */

    /* border: 2px solid red; */
    /* background-image: url("../../images/whyusimg.jpg");
    background-size: 100% 100%;
    object-fit: cover; */
  }
}
